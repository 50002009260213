import { setRemoteDefinitions } from '@nrwl/angular/mf';
const origin = window.location.origin;
const domain = window.location.host;
const dynamicRemotes = Object.freeze({
    orchestrations: origin + '/apps/orchestrations',
    workflows: origin + '/apps/workflows',
    dashboard: origin + '/apps/dashboard',
    agent: origin + '/apps/agent',
    users: origin + '/apps/users',
    accesslogs: origin + '/apps/accesslogs',
    flowmap: origin + '/apps/flowmap',
    'identity-explorer': origin + '/apps/identity-explorer',
    aggregation: origin + '/apps/aggregation',
    profile: origin + '/apps/profile',
    tasks: origin + '/apps/tasks',
    piam: origin + '/apps/piam',
    'identity-ledger': origin + '/apps/identity-ledger',
    configuration: origin + '/apps/configuration',
    applications: origin + '/apps/applications',
    public : origin + '/apps/public',
    'identity-intelligence': origin + '/apps/identity-intelligence',
    flowmapv2: origin + '/apps/flowmapv2',
    'data-loader': origin +'/apps/data-loader',
    'aggregation-v2': origin + '/apps/aggregation-v2',
    'data-insight': origin +'/apps/data-insight',
    'script-orchestration': origin + '/apps/script-orchestration',
    'access-request': "/apps/access-request",
    'workflow-v1': origin + '/apps/workflow-v1',
    'assets-management': origin + '/apps/assets-management',
    'task-management': origin + '/apps/task-management',
    'ops-manager': origin + '/apps/ops-manager',
    'hr-to-ad-sync': origin + '/apps/hr-to-ad-sync'
});
const localRemotes = Object.freeze({
    orchestrations: "http://localhost:4201",
    workflows: "http://localhost:4202",
    dashboard: "http://localhost:4203",
    agent: "http://localhost:4204",
    users: "http://localhost:4205",
    accesslogs: "http://localhost:4206",
    flowmap: "http://localhost:4207",
    'identity-explorer': "http://localhost:4208",
    aggregation: "http://localhost:4210",
    profile: "http://localhost:4211",
    tasks: "http://localhost:4212",
    piam: "http://localhost:4213",
    'identity-ledger': "http://localhost:4215",
    configuration: "http://localhost:4218",
    applications: "http://localhost:4220",
    public : "http://localhost:4221",
    flowmapv2: "http://localhost:4223",
    'data-loader': "http://localhost:4224",
    'aggregation-v2':"http://localhost:4225",
    'data-insight': "http://localhost:4226",
    'script-orchestration':'http://localhost:4227',
    'access-request': "http://localhost:4228",
    'workflow-v1':'http://localhost:4229',
    'assets-management': 'http://localhost:4228',
    'task-management': "http://localhost:4230",
    'hr-to-ad-sync': "http://localhost:4231",
    'ops-manager': "http://localhost:4232"
});

if(domain.startsWith("localhost")){
    setRemoteDefinitions(localRemotes);
}
else{
    setRemoteDefinitions(dynamicRemotes);
}

import('./bootstrap').catch(err => console.error(err))
